import React from "react"
// import {ProjectProvider} from "./src/contexts/TimeContext";
import Theme from "./src/style/Theme";
import {TimeProvider} from "./src/contexts/TimeContext";
// import {LanguageProvider} from "./src/contexts/LanguageContext";

export const wrapRootElement = ({element}) => {

    return (
        <TimeProvider>
            <Theme>
                {/*<LanguageProvider>*/}
                {element}
                {/*</LanguageProvider>*/}
            </Theme>
        </TimeProvider>
    )
}