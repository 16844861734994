import React, {createContext, useContext, useEffect, useState} from "react";

export const TimeContext = createContext();

// We wrap the provider in a nice little component
// which will hold the state and provide methods to
// update the state
function TimeProvider(props) {
    const now = new Date(
        // 'December 17, 1995 17:00:00'
    )
    const hours = now.getHours()
    const [quarter, setQuarter] = useState(null)
    // let quarter = -1

    useEffect(() => {
        if (hours >= 6 && hours < 10) {
            // quarter = {index: 0, time: "dawn"}
            setQuarter({index: 0, time: "dawn"})

        } else if (hours >= 10 && hours < 18) {
            // quarter = {index: 1, time: "day"}
            setQuarter({index: 1, time: "day"})

        } else if (hours >= 18 && hours < 22) {
            // quarter = {index: 2, time: "dusk"}
            setQuarter({index: 2, time: "dusk"})

        } else {
            // quarter = {index: 3, time: "night"}
            setQuarter({index: 3, time: "night"})

        }
    }, [setQuarter, hours])

    const timeData = {now, quarter};

    return <TimeContext.Provider value={timeData} {...props} />;
}

// Here we create a custom hook that allows us to consume
// the context
function useTimeContext() {
    return useContext(TimeContext);
}

export {TimeProvider, useTimeContext};
