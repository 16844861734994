import {ThemeProvider} from "styled-components";
import React from "react";
import {useTimeContext} from "../contexts/TimeContext";

const Theme = (props) => {
    const time = useTimeContext();

    const theme = {
        colors: {
            blue: "#45A0FF",
            paleBlue: "#B9DBFF",
            orange: "#FF6533",
            yellow: "#FCC147",
            black: "#000000",
            white: "#FFFFFF",
            paleGrey: "#F2F2F2",

            get backgroundList() {
                return [this.blue, this.white, this.orange, this.black]
            },
            get complementList() {
                return [this.black, this.blue, this.yellow, this.orange]
            },
            get textList() {
                return [this.white, this.black, this.white, this.white]
            },

            get background() {
                return time.quarter ? this.backgroundList[time.quarter.index] : this.black;
            },
            get complement() {
                return time.quarter ? this.complementList[time.quarter.index] : this.black;
            },
            get text() {
                return time.quarter ? this.textList[time.quarter.index] : this.black;
            },
        },

        fonts: {
            Lunchtype22: {
                family: "Lunchtype22",

                // weights
                regular: "400",
                medium: "500",

                // sizes
                standard: "1rem",
            },
            Lunchtype24: {
                family: "Lunchtype24",

                // weights
                regular: "400",
                medium: "500",

                // sizes
                standard: "1rem",
            },
            GothicA1: {
                family: "GothicA1",

                // weights
                regular: "400",
                // medium: "500",

                // sizes
                standard: "1rem",
            },
        },

        text: {
            standard: {
                family: "Lunchtype22",
                size: {
                    regular: "1.406rem", // 22.5px
                    small: "1rem", // 16px
                },
                weight: "400",
                lineHeight: "auto",
                color: "text",
                transform: "none",
            },
            standardKo: {
                family: "GothicA1",
                size: {
                    regular: "1.406rem", // 22.5px
                    small: "1rem", // 16px
                },
                weight: "500",
                lineHeight: "auto",
                color: "text",
                transform: "none",
            },
            logo: {
                family: "Lunchtype24",
                size: {
                    regular: "3.125rem", // 50px
                    small: "2.083rem", // 33.33px
                },
                weight: "500",
                lineHeight: "auto",
                color: "text",
                transform: "uppercase",
            },
            languages: {
                family: "Lunchtype24",
                size: {
                    regular: "1.875rem", // 30px
                    small: "1.25rem", // 20px
                },
                weight: "500",
                lineHeight: "auto",
                color: "text",
                transform: "uppercase",
            },
            languagesKo: {
                family: "GothicA1",
                size: {
                    regular: "1.875rem", // 30px
                    small: "1.25rem", // 20px
                },
                weight: "600",
                lineHeight: "auto",
                color: "text",
                transform: "uppercase",
            },
            title: {
                family: "Lunchtype24",
                size: {
                    regular: "5.313rem", // 85px
                    small: "3rem", // 48px
                    // small: "5.313rem", // 85px
                },
                weight: "400",
                // lineHeight: "4.375rem",
                lineHeight: "85%",
                color: "text",
                transform: "none",
            },
            // divided by 1.214285714285714
            titleKo: {
                family: "GothicA1",
                size: {
                    regular: "5.313rem", // 85px
                    small: "3rem", // 48px
                    // small: "5.313rem", // 85px
                },
                weight: "500",
                // lineHeight: "4.375rem",
                lineHeight: "auto",
                color: "text",
                transform: "none",
            },
            subtitle: {
                family: "Lunchtype24",
                size: {
                    regular: "2.5rem", // 40px
                    small: "1.625rem", // 26px
                },
                weight: "400",
                lineHeight: "auto",
                color: "text",
                transform: "none",
            },
            subtitleKo: {
                family: "GothicA1",
                size: {
                    regular: "2.5rem", // 40px
                    small: "1.5rem", // 24px
                },
                weight: "500",
                // lineHeight: "4.375rem",
                lineHeight: "150%",
                color: "text",
                transform: "none",
            },
            contentTitle: {
                family: "Lunchtype22",
                size: {
                    regular: "2.188rem", // 35px
                    small: "1.5rem", // 30px
                },
                weight: "500",
                lineHeight: "auto",
                color: "text",
                transform: "none",
            },
            menuButton: {
                family: "Lunchtype22",
                size: {
                    regular: "1.406rem", // 22.5px
                    small: "1.406rem", // 22.5px
                },
                weight: "400",
                lineHeight: "auto",
                color: "text",
                transform: "none",
            },
        },

        sizes: {
            globalWidth: "73rem",
            globalMargins: "5rem",
            headerHeight: "4rem",
            headerHeightMobile: "3rem"
        },

        breakPoints: {
            // s: "37.5rem", // 600px
            xxs: "20rem", // 320px
            xs: "26.563rem", // 425px
            s: "48rem", // 768px
            m: "64rem", // 1024px
            l: "83.125rem" // 1330px
        },

        spacer: {
            smallest: ["0.25rem", "0.25rem"],
            smaller: ["0.5rem", "0.5rem"],
            small: ["1rem", "0.5rem"],
            medium: ["2rem", "1rem"],
            large: ["3rem", "2rem"],
            larger: ["8rem", "4rem"],
        },

        paddings: {
            left: "2rem",
            right: "2rem"
        },

        radii: {
            standard: "1.25rem",
            buttonLink: "0.375rem",
            tile: "1rem",
        },

        filters: {
            cardDropShadow: "drop-shadow(-5px 3px 10px rgba(24, 91, 224, 0.25))",
            titleDropShadow: "drop-shadow(0px 0px 10px rgba(24, 91, 224, 0.25))"
        },

        time: {
            date: "DD.MM.YYYY",
            dateTime: "DD.MM.YYYY - HH:mm",
        }
    };

    return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};

export default Theme;